<template>
  <Card class="user-form">
    <Form ref="formUser" style="max-width:500px" :model="userItem" :rules="ruleValidate" :label-width="140">
      <Form-item label="姓名" prop="Name">
        <Input v-model="userItem.Name" placeholder="请输入姓名"></Input>
      </Form-item>
      <Form-item label="身份证号码" prop="IdNumber">
        <Input v-model="userItem.IdNumber" placeholder="请输入身份证号码"></Input>
      </Form-item>
      <Form-item label="电话号码" prop="MobilePhoneNumber">
        <Input v-model="userItem.MobilePhoneNumber" placeholder="请输入电话号码"></Input>
      </Form-item>
      <Form-item label="用户名" prop="Username">
        <Input v-model="userItem.Username" placeholder="请输入用户名"></Input>
      </Form-item>
      <Form-item label="登陆密码" prop="Password" :rules="
        editMode ? [
            { validator: validatePass, trigger: 'blur' }
          ] :
          [
            { required: true, message: '登陆密码不能为空', trigger: 'blur' },
            // { type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' },
            { validator: validatePass, trigger: 'blur' }
          ]
      ">
        <Input type="password" v-model="userItem.Password" placeholder="请输入登陆密码"></Input>
      </Form-item>
      <Form-item label="重复输入登陆密码" prop="rePassword" :rules="
        editMode ? [
            { validator: validateConfirmPassword, trigger: 'blur' }
          ] :
          [
            { required: true, message: '重复登陆密码不能为空', trigger: 'blur' },
            // { type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' }
            { validator: validateConfirmPassword, trigger: 'blur' }
          ]
      ">
        <Input type="password" v-model="userItem.rePassword" placeholder="请重复输入登陆密码"></Input>
      </Form-item>
      <FormItem label="用户角色" prop="Permissions">
        <Select v-model="userItem.Permissions" multiple placeholder="请选择">
          <Option
            v-for="(p,pIdx) in $common.getEnumSelect($common.permissions)"
            :key="pIdx"
            :value="parseInt(p.key)">{{p.value}}</Option>
        </Select>
      </FormItem>
      <FormItem v-if="submit===null||submit===undefined">
        <Button type="primary" size="large" long :loading="submitting" @click="submitFrom">提交</Button>
      </FormItem>
    </Form>
  </Card>
</template>

<script>
export default {
  props: ['user', 'submit'],
  watch: {
    user: function (newVal) {
      if (newVal) {
        Object.assign(this.userItem, newVal)
        this.userItem.Permissions = this.getPermissions(this.userItem.Permission)
        this.editMode = true
      } else {
        this.editMode = false
        this.userItem = {
          Name: '',
          IdNumber: '',
          MobilePhoneNumber: '',
          Username: '',
          Password: '',
          rePassword: '',
          Permission: null,
          Permissions: []
        }
      }
    },
    submit: function (newVal) {
      console.log(newVal)
      if (newVal === true) {
        this.submitFrom()
      }
    }
  },
  data () {
    return {
      visible: false,
      editMode: false,
      userItem: {
        Name: '',
        IdNumber: '',
        MobilePhoneNumber: '',
        Username: '',
        Password: '',
        rePassword: '',
        Permission: null,
        Permissions: []
      },
      ruleValidate: {
        Name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        Username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        IdNumber: [
          { required: true, message: '身份证号码不能为空', trigger: 'blur' },
          { type: 'string', pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号码不合法', trigger: 'blur' }
        ],
        MobilePhoneNumber: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' },
          { type: 'string', pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号码不合法', trigger: 'blur' }
        ],
        // Permission: [
        //   { type: 'number', required: true, message: '用户角色不能为空', trigger: 'blur' }
        // ]
        Permissions: [
          { required: true, validator: this.arrayValidator, trigger: 'change' }
        ]
      },
      submitEnabled: true,
      submitting: false
    }
  },
  mounted () {
  },
  methods: {
    arrayValidator (rule, value, callback) {
      if (!value || !(value instanceof Array) || !value.length) {
        callback(new Error('最少选择一项'))
      } else {
        callback()
      }
    },
    validatePass (rule, value, callback) {
      if (value === '' && this.editMode === false) {
        callback(new Error('请输入密码'))
      } else {
        if (this.userItem.rePassword !== '') {
          // 对第二个密码框单独验证
          this.$refs.formUserNew.validateField('rePassword')
        }
        callback()
      }
    },
    validateConfirmPassword (rule, value, callback) {
      if (value === '' && this.editMode === false) {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userItem.Password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    getPermission () {
      let perm = 0
      this.userItem.Permissions.forEach((p) => {
        perm += p
      })
      return perm
    },
    getPermissions (permission) {
      let permissions = []
      let perms = this.$common.getEnumSelect(this.$common.permissions)
      perms.forEach((p) => {
        let pValue = parseInt(p.key)
        if ((permission & pValue) === pValue) {
          permissions.push(pValue)
        }
      })
      if (permission % 2 === 0) {
        permissions.push(1)
      }
      console.log(permissions)
      return permissions
    },
    submitFrom () {
      if (this.user && this.user.Id) {
        return this.editUser()
      } else {
        return this.createUser()
      }
    },
    createUser () {
      this.$refs['formUser'].validate((valid) => {
        if (valid) {
          this.userItem.Permission = this.getPermission()
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.$Api.Auth(this).addUser(this.userItem).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.$emit('done')
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    editUser () {
      this.$refs['formUser'].validate((valid) => {
        if (valid) {
          this.userItem.Permission = this.getPermission()
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.$Api.Auth(this).modifyUser(this.userItem).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.$emit('done')
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
