var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"user-form"},[_c('Form',{ref:"formUser",staticStyle:{"max-width":"500px"},attrs:{"model":_vm.userItem,"rules":_vm.ruleValidate,"label-width":140}},[_c('Form-item',{attrs:{"label":"姓名","prop":"Name"}},[_c('Input',{attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.userItem.Name),callback:function ($$v) {_vm.$set(_vm.userItem, "Name", $$v)},expression:"userItem.Name"}})],1),_c('Form-item',{attrs:{"label":"身份证号码","prop":"IdNumber"}},[_c('Input',{attrs:{"placeholder":"请输入身份证号码"},model:{value:(_vm.userItem.IdNumber),callback:function ($$v) {_vm.$set(_vm.userItem, "IdNumber", $$v)},expression:"userItem.IdNumber"}})],1),_c('Form-item',{attrs:{"label":"电话号码","prop":"MobilePhoneNumber"}},[_c('Input',{attrs:{"placeholder":"请输入电话号码"},model:{value:(_vm.userItem.MobilePhoneNumber),callback:function ($$v) {_vm.$set(_vm.userItem, "MobilePhoneNumber", $$v)},expression:"userItem.MobilePhoneNumber"}})],1),_c('Form-item',{attrs:{"label":"用户名","prop":"Username"}},[_c('Input',{attrs:{"placeholder":"请输入用户名"},model:{value:(_vm.userItem.Username),callback:function ($$v) {_vm.$set(_vm.userItem, "Username", $$v)},expression:"userItem.Username"}})],1),_c('Form-item',{attrs:{"label":"登陆密码","prop":"Password","rules":_vm.editMode ? [
          { validator: _vm.validatePass, trigger: 'blur' }
        ] :
        [
          { required: true, message: '登陆密码不能为空', trigger: 'blur' },
          // { type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' },
          { validator: _vm.validatePass, trigger: 'blur' }
        ]}},[_c('Input',{attrs:{"type":"password","placeholder":"请输入登陆密码"},model:{value:(_vm.userItem.Password),callback:function ($$v) {_vm.$set(_vm.userItem, "Password", $$v)},expression:"userItem.Password"}})],1),_c('Form-item',{attrs:{"label":"重复输入登陆密码","prop":"rePassword","rules":_vm.editMode ? [
          { validator: _vm.validateConfirmPassword, trigger: 'blur' }
        ] :
        [
          { required: true, message: '重复登陆密码不能为空', trigger: 'blur' },
          // { type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' }
          { validator: _vm.validateConfirmPassword, trigger: 'blur' }
        ]}},[_c('Input',{attrs:{"type":"password","placeholder":"请重复输入登陆密码"},model:{value:(_vm.userItem.rePassword),callback:function ($$v) {_vm.$set(_vm.userItem, "rePassword", $$v)},expression:"userItem.rePassword"}})],1),_c('FormItem',{attrs:{"label":"用户角色","prop":"Permissions"}},[_c('Select',{attrs:{"multiple":"","placeholder":"请选择"},model:{value:(_vm.userItem.Permissions),callback:function ($$v) {_vm.$set(_vm.userItem, "Permissions", $$v)},expression:"userItem.Permissions"}},_vm._l((_vm.$common.getEnumSelect(_vm.$common.permissions)),function(p,pIdx){return _c('Option',{key:pIdx,attrs:{"value":parseInt(p.key)}},[_vm._v(_vm._s(p.value))])}),1)],1),(_vm.submit===null||_vm.submit===undefined)?_c('FormItem',[_c('Button',{attrs:{"type":"primary","size":"large","long":"","loading":_vm.submitting},on:{"click":_vm.submitFrom}},[_vm._v("提交")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }