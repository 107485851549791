<template>
  <Modal
    v-model="visible"
    @on-cancel="returnPath"
    title="新增用户">
    <user-form :submit="submit" @done="returnPath" />
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" @click="submit=true">提交</Button>
    </div>
  </Modal>
</template>

<script>
import UserForm from './UserForm.vue'
export default {
  props: ['show'],
  components: {
    UserForm
  },
  watch: {
    'show': function (newVal) {
      if (newVal) {
        this.visible = true
      } else {
        this.visible = false
      }
    }
  },
  data () {
    return {
      visible: false,
      submit: false
    }
  },
  mounted () {
  },
  methods: {
    returnPath () {
      this.submit = false
      this.$emit('close')
      this.$Modal.remove()
    }
  }
}
</script>

<style scoped>
</style>
